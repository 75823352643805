<template>
<div>
    <NavBar/> 
		<router-view ></router-view>
    <Footer/>
</div>
</template>

<script>

import Home from './views/Home';
import NavBar from './components/Nav-bar.vue';
import Footer from './components/Footer.vue';


export default {
  name: 'App',
  components: {
    Home,
    NavBar,
    Footer,
},

  data: () => ({
    //
  }),
};
</script>