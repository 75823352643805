import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import router from './router'
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueI18n);

const messages = {
  tr: require('./locales/tr.json'),
  en: require('./locales/en.json'),
};

const browserLanguage = navigator.language.split('-')[0];
const supportedLanguages = ['tr', 'en'];
const defaultLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const i18n = new VueI18n({
  locale: defaultLanguage,  // Tarayıcı dilini kullan
  fallbackLocale: 'tr',    // Eğer belirlenen dil yoksa Türkçe'yi kullan
  messages,
});

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');