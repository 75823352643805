<template>
  <section id="services" class="resources-section py-24">
    <div class="max-w-screen-xl mx-auto text-center mb-12">

      <p class="text-sm font-semibold text-orange-400 uppercase tracking-wide mb-4">
        {{ $t('servicess.subtitle') }}
      </p>
      <!-- Başlık -->
      <h2 class="text-5xl font-extrabold leading-tight mb-6">
        {{ $t('servicess.title') }}
      </h2>

      <!-- Navigasyon Menüsü -->
      <div class="relative mt-6">
        <div class="flex justify-center space-x-6 text-gray-600">
          <button
            v-for="(item, index) in navItems"
            :key="index"
            class="relative text-lg font-semibold pb-2 transition duration-300"
            :class="selectedNav === item.name ? 'text-blue-400' : ''"
            @click="changeTab(item.name, index)"
            ref="navButtons"
          >
            {{ $t('servicess.navItems[' + index + '].label') }}
          </button>
        </div>

        <!-- Alt Çizgi (Animasyonlu) -->
        <div
          class="absolute bottom-0 h-1 bg-blue-400 transition-all duration-500 ease-in-out"
          :style="{ left: `${underlinePosition}px`, width: `${underlineWidth}px` }"
        ></div>
      </div>
    </div>

    <!-- İçerik -->
    <div class="flex flex-col lg:flex-row items-center max-w-screen-xl mx-auto px-6">
      <!-- Dinamik İçerik -->
      <template v-if="selectedNav === 'Resources'">
        <div class="lg:w-1/2 flex justify-center mb-8 lg:mb-0">
          <img src="@/assets/img/hardware3.png" alt="Resources" class="w-full max-w-md">
        </div>
        <div class="lg:w-1/2 text-left">
          <h3 class="text-2xl font-bold text-gray-800 mb-4">{{ $t('servicess.sections.Resources.heading') }}</h3>
          <p class="text-gray-600 text-lg leading-relaxed mb-6">
            {{ $t('servicess.sections.Resources.content') }}
          </p>
        </div>
      </template>

      <template v-if="selectedNav === 'Hardware_RF'">
        <div class="lg:w-1/2 flex justify-center mb-8 lg:mb-0">
          <img src="@/assets/img/configuration.png" alt="Hardware RF" class="w-full max-w-md">
        </div>
        <div class="lg:w-1/2 text-left">
          <h3 class="text-2xl font-bold text-gray-800 mb-4">{{ $t('servicess.sections.Hardware_RF.heading') }}</h3>
          <p class="text-gray-600 text-lg leading-relaxed mb-6">
            {{ $t('servicess.sections.Hardware_RF.content') }}
          </p>
        </div>
      </template>

      <template v-if="selectedNav === 'Software'">
        <div class="lg:w-1/2 flex justify-center mb-8 lg:mb-0">
          <img src="@/assets/img/deploy.png" alt="Software" class="w-full max-w-md">
        </div>
        <div class="lg:w-1/2 text-left">
          <h3 class="text-2xl font-bold text-gray-800 mb-4">{{ $t('servicess.sections.Software.heading') }}</h3>
          <p class="text-gray-600 text-lg leading-relaxed mb-6">
            {{ $t('servicess.sections.Software.content') }}
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        { name: "Resources", label: "Build your hardware" },
        { name: "Hardware_RF", label: "Integrate and Configure" },
        { name: "Software", label: "Push and Deploy" },
      ],
      selectedNav: "Resources",
      underlinePosition: 0,
      underlineWidth: 0,
    };
  },
  methods: {
  changeTab(name, index) {
    this.selectedNav = name;
    this.updateUnderline(index);
  },
  updateUnderline(index) {
    this.$nextTick(() => {
      const buttons = this.$refs.navButtons;
      if (buttons && buttons[index]) {
        const button = buttons[index];
        // Mobil ve masaüstü için konumu ve genişliği dinamik hesapla
        const isMobile = window.innerWidth <= 768;
        this.underlinePosition = isMobile
          ? button.getBoundingClientRect().left - button.offsetParent.getBoundingClientRect().left
          : button.offsetLeft;
        this.underlineWidth = button.offsetWidth;
      }
    });
  },
},
mounted() {
  this.$nextTick(() => {
    const initialIndex = this.navItems.findIndex((item) => item.name === this.selectedNav);
    setTimeout(() => {
      this.updateUnderline(initialIndex);
    }, 0);

    // Ekran boyutu değişikliklerini dinle
    window.addEventListener("resize", () => {
      const currentIndex = this.navItems.findIndex((item) => item.name === this.selectedNav);
      this.updateUnderline(currentIndex);
    });
  });
},
};
</script>

<style scoped>
button {
  cursor: pointer;
}
button:focus {
  outline: none;
}
</style>