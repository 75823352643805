import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/novaliot',
    component: () => import('../views/Novaliot.vue'),
    meta: { onlyMenu: true },
  },
  {
    path: '/aboutus',
    component: () => import('../views/AboutUs.vue'),
    meta: { onlyMenu: true },
  },
  {
    path: '/contactus',
    component: () => import('../views/ContactUs.vue'),
    meta: { onlyMenu: true },
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;