
<template>
  <section id="home">
    <!-- Navbar -->
    <nav
      :class="['fixed top-0 left-0 w-full z-20', menuClass]"
      class="h-16"
    >
      <div class="flex items-center justify-between max-w-screen-xl mx-auto h-full px-4">
        <router-link to="/#home" class="flex items-center" @click="closeMenu">
          <img src="@/assets/img/logo-navbar.png" alt="Logo" class="h-20">
          <span class="ml-3 text-xl">
            <span :class="logoClass">{{ $t('heroSection.titleone') }}</span>
            <span class="font-bold" :class="logoClass"> {{ $t('heroSection.titletwo') }}</span>
          </span>
        </router-link>

        <!-- Menü Butonu (Mobil) -->
        <button
          @click="toggleMenu"
          class="lg:hidden block focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            class="h-8 w-8 transition-all duration-200" :class="iconColorClass">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>

        <!-- Menü İçeriği -->
        <ul
          :class="[
            'lg:flex lg:space-x-8 lg:items-center',
            isMenuOpen ? 'absolute top-16 left-0 w-full bg-white shadow-md p-4 opacity-100' : 'hidden lg:flex'
          ]"
          class="ml-auto text-center lg:text-left transition-all duration-300 ease-in-out"
        >
          <li>
            <router-link to="/novaliot" class="nav-item" @click="closeMenu">
              {{ $t('navbar.navaliot') }}
            </router-link>
          </li>
          <li>
            <router-link to="/#services" class="nav-item" @click="closeMenu">
              {{ $t('navbar.services') }}
            </router-link>
          </li>
          <li>
            <router-link to="/aboutus" class="nav-item" @click="closeMenu">
              {{ $t('navbar.aboutUs') }}
            </router-link>
          </li>
          <li>
            <router-link to="/contactus" class="nav-item" @click="closeMenu">
              {{ $t('navbar.contact') }}
            </router-link>
          </li>
          <!-- <li class="relative">
            <button
              @click="toggleLanguageDropdown"
              class="nav-item flex justify-center items-center w-32 mx-auto"
            >
              {{ language.toUpperCase() }}
            </button>
            <ul
              v-if="isLanguageDropdownOpen"
              class="dropdown-menu"
            >
              <li><button @click="changeLanguage('en')">EN</button></li>
              <li><button @click="changeLanguage('tr')">TR</button></li>
            </ul>
          </li> -->
        </ul>
      </div>
    </nav>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isScrolled: false,
      isMenuOpen: false,
      isLanguageDropdownOpen: false,
      language: "en",
    };
  },
  computed: {
    menuClass() {
      return this.isScrolled || this.isMenuOpen ? 'bg-white text-black shadow-md' : 'bg-transparent text-white';
    },
    logoClass() {
      return this.isScrolled || this.isMenuOpen ? 'text-black' : 'text-white';
    },
    iconColorClass() {
      return this.isScrolled || this.isMenuOpen ? 'text-black' : 'text-white';
    }
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    changeLanguage(lang) {
      this.language = lang;
      this.$i18n.locale = lang;
      this.isLanguageDropdownOpen = false;
    },
    closeMenuOnRouteChange() {
      this.isMenuOpen = false;
      this.isLanguageDropdownOpen = false; // Dil menüsü de kapanacak
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$watch('$route', this.closeMenuOnRouteChange);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* Menü Öğeleri */
.nav-item {
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  text-align: center; /* Yazıyı ortala */
}

/* Açılan Dil Menüsü */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); /* Menü tam ortada olacak */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100px;
  text-align: center;
  padding: 8px 0;
  z-index: 30;
  display: block;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li button {
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dropdown-menu li button:hover {
  background-color: #f3f4f6;
}

</style>