<template>
  <section>
    <div class="hero-section bg-slate-900 text-white h-screen flex flex-col-reverse lg:flex-row items-center justify-center px-6 lg:px-24">
            <div class="w-full lg:w-[45%] max-w-lg  lg:text-left mb-8 lg:mb-0">
        <p class="text-sm font-semibold text-orange-400 uppercase tracking-wide mb-4">
            {{ $t('heroSection.subheading') }}
        </p>
        <h1 class="text-3xl lg:text-5xl font-extrabold leading-tight mb-6">
            {{ $t('heroSection.title') }}
        </h1>
        <p class="text-gray-300 mb-6">
            {{ $t('heroSection.description') }}
        </p>
        
        <div class="flex lg:justify-start">
          <router-link to="/novaliot">
            <button class="border border-blue-400 text-blue-400 text-white font-semibold px-6 py-2 lg:px-8 lg:py-3 rounded-full transition duration-300">
                {{ $t('heroSection.secondaryButton') }}
            </button>
          </router-link>
        </div>
      </div>

      <!-- Sağ Taraf: Resim -->
      <div class="w-full lg:w-1/2 flex justify-center items-center">
        <img src="@/assets/img/header3.png" 
             alt="3D Hero Image" 
             class="w-3/4 lg:w-full max-h-96 object-contain drop-shadow-xl animate-float">
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  .hero-section img {
    max-width: 100%;
  }
}
</style>