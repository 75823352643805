<template>
  <v-app>
    <HeroSection></HeroSection>
    <SectionOne></SectionOne>
    <SectionTwo></SectionTwo>
    <SectionThree></SectionThree>
    <SectionFour></SectionFour>
    <SectionFive></SectionFive>
  </v-app>
</template>

<script>
import HeroSection from '../components/HeroSection';
import SectionOne from '@/components/SectionOne.vue';
import SectionTwo from '@/components/SectionTwo.vue';
import SectionThree from '@/components/SectionThree.vue';
import SectionFive from '@/components/SectionFive.vue';
import SectionFour from '@/components/SectionFour.vue';


export default {
  name: 'App',

  components: {
    HeroSection,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive
  },

  data: () => ({
    //
  }),
};
</script>


<style >
v-app{
  z-index: 0;
}
</style>